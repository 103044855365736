<template>
  <div class="hospital-list">
      <h1>Registered Hospital Administrators</h1>
    <CDataTable
      :striped="striped"
      :bordered="bordered"
      :small="small"
      :items="getItems"
      :fields="fields"
      :items-per-page="small ? limit : 5"
      pagination
    ></CDataTable>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HospitalAdminList",
  props: {
    activePage: {
        type: Number,
        default: 1
    },
    page: {
        type: Number,
        default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    fields: {
      type: Array,
      default() {
        return ["Name", 
        "Contact_No", 
        "Email", 
        "Hospital_Name", 
        // "Date_of_Birth",
        // "Website"
        ];
      }
    },
  },
  data() {
    return {
        small: true,
        striped: true,
        bordered: true,
    };
  },
  computed: {
      ...mapGetters(['getHospitalAdminList']),
    getItems() {
      return this.getHospitalAdminList.map(data => {
          const container = {}
            container.Name = data.first_name.concat(' ', data.surname)
            container.Contact_No = data.contact_phone ? data.contact_phone : '--'
            container.Email = data.email ? data.email : '--'
            container.Hospital_Name = data.customer && data.customer.name ? data.customer.name :'--'
            // container.Date_of_Birth = data.dob ? data.dob : '--'
            // container.Website= '--'
            return container;
        })
      },
      
    pagination(){
    }
  },
  mounted(){
      this.FetchHospitalAdminList()
  },
  methods: {
    ...mapActions(['FetchHospitalAdminList'])
  }
};
</script>

<style scoped>
.hospital-list {
  margin-bottom: 5rem;
}
</style>